import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../static/PMDLogo.svg"; // import logo SVG file

function Navbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div>
            <nav className="bg-white border-gray-200 light:bg-gray-900">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <Link to="/">
                        <div className="flex items-center">
                            <img src={logo} className="h-8 mr-3 sm:text-sm" alt="PMD Construction Consulting Logo" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap light:text-white hidden sm:inline">
                                PMD Construction Consulting
                            </span>
                        </div>
                    </Link>

                    <button
                        type="button"
                        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 light:text-gray-400 light:hover:bg-gray-700 light:focus:ring-gray-600"
                        onClick={toggleMobileMenu}
                        aria-controls="navbar-default"
                        aria-expanded={isMobileMenuOpen}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <div
                        className={`w-full md:block md:w-auto ${isMobileMenuOpen ? "block" : "hidden"}`}
                        id="navbar-default"
                    >
                        <div className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white light:bg-gray-800 md:light:bg-gray-900 light:border-gray-700">
                            <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">
                                Home
                            </Link>
                            <Link to="/about" className="text-sm font-semibold leading-6 text-gray-900">
                                About
                            </Link>
                            <Link to="/projects" className="text-sm font-semibold leading-6 text-gray-900">
                                Projects
                            </Link>
                            <Link to="/seminars" className="text-sm font-semibold leading-6 text-gray-900">
                                Seminars
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;

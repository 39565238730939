import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

interface YoutubeEmbedProps {
    embedId: string;
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ embedId }) => {
  // Ensure the embedId is defined and is a valid YouTube video ID
  if (!embedId) {
    return <div>Invalid video ID.</div>;
  }

  // Create the full YouTube embed URL
  const youtubeEmbedUrl = `https://www.youtube.com/embed/${embedId}`;

  return (
    <div className="video-responsive">
        <iframe
        width="500"
        height="400"
        src={youtubeEmbedUrl}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        >
        </iframe>
    </div>
  );
};


function Seminars() {
    return (
        <div>
            <Navbar />
            <div className="container my-24 mx-auto md:px-6">
                <div className="px-6 py-12 md:px-12 lg:text-left">
                    <div className="container mx-auto">
                        <section>
                            <div className="py-8 lg:py-10 px-4 mx-auto max-w-screen-md">
                                <div className="grid grid-cols-1 items-center gap-12">
                                    <div className="mt-10 lg:mt-0">
                                        <h2 className="mb-4 text-5xl tracking-tight font-extrabold text-center text-[hsl(218,81%,95%)] light:text-white">
                                            Past Seminars
                                        </h2>
                                        <div className="mb-6 flex flex-col items-center rounded-lg light:bg-neutral-700 md:flex-row md:justify-center md:items-center">
                                            <div className="flex flex-col justify-center items-center p-6">
                                                <YoutubeEmbed embedId="dNJ6-ncLyIc" />
                                            </div>
                                        </div>

                                        <div className="mb-6 flex flex-col items-center rounded-lg light:bg-neutral-700 md:flex-row md:justify-center md:items-center">
                                            <div className="flex flex-col justify-center items-center p-6">
                                                <YoutubeEmbed embedId="oOF2_7fId6o" />
                                            </div>
                                        </div>

                                        <div className="mb-6 flex flex-col items-center rounded-lg light:bg-neutral-700 md:flex-row md:justify-center md:items-center">
                                            <div className="flex flex-col justify-center items-center p-6">
                                                <YoutubeEmbed embedId="OJI5czoChWI" />
                                            </div>
                                        </div>


                                        <div className="mb-6 flex flex-col items-center rounded-lg light:bg-neutral-700 md:flex-row md:justify-center md:items-center">
                                            <div className="flex flex-col justify-center items-center p-6">
                                                <YoutubeEmbed embedId="rHfDRljq9VE" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Seminars;

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (e: any) => {
        e.preventDefault();

        // add a null check to ensure that form.current is not null
        // before proceeding with the function call.
        if (form.current) {
            emailjs
                .sendForm(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    form.current,
                    process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    },
                );
            e.target.reset();
        }
    };

    return (
        <div>
            <Navbar />
            <div className="container my-24 mx-auto md:px-6">
                <div className="px-6 text-center md:px-12 lg:text-left">
                    <div className="container mx-auto">
                        <section>
                            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-[hsl(218,81%,95%)] light:text-white">
                                    Contact Us
                                </h2>
                                <p className="mb-8 lg:mb-16 font-light text-center text-[hsl(218,81%,95%)] light:text-gray-400 sm:text-xl">
                                    Got a construction-related question? Need expert advice on your building project?
                                    Looking for information about our services? We're here to help, just let us know.
                                </p>
                                <form ref={form} onSubmit={sendEmail} className="space-y-8">
                                    <div>
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium text-[hsl(218,81%,95%)] light:text-gray-300"
                                        >
                                            Name*
                                        </label>
                                        <input
                                            type="name"
                                            id="name"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
                                            placeholder="Enter your name"
                                            name="user_name"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block mb-2 text-sm font-medium text-[hsl(218,81%,95%)] light:text-gray-300"
                                        >
                                            Email*
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
                                            placeholder="Enter your email"
                                            name="user_email"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="phone"
                                            className="block mb-2 text-sm font-medium text-[hsl(218,81%,95%)] light:text-gray-300"
                                        >
                                            Phone
                                        </label>
                                        <input
                                            type="phone"
                                            id="phone"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
                                            placeholder="Enter your phone number"
                                            name="user_phone"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="subject"
                                            className="block mb-2 text-sm font-medium text-[hsl(218,81%,95%)] light:text-gray-300"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            id="subject"
                                            className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500 light:shadow-sm-light"
                                            placeholder="Type the subject"
                                            name="subject"
                                            required
                                        />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label
                                            htmlFor="message"
                                            className="block mb-2 text-sm font-medium text-[hsl(218,81%,95%)] light:text-gray-400"
                                        >
                                            Your message
                                        </label>
                                        <textarea
                                            id="message"
                                            rows={6}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500"
                                            placeholder="Type your message here..."
                                            name="message"
                                            required
                                        ></textarea>
                                    </div>
                                    <button
                                        type="submit"
                                        className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                    >
                                        Send message
                                    </button>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

function Home() {
    return (
        <div>
            <Navbar />
            <div className="container my-24 mx-auto md:px-6">
                <div className="px-6 py-12 text-center md:px-12 lg:text-left">
                    <div className="container mx-auto">
                        <div className="grid items-center gap-12 lg:grid-cols-2">
                            {/* column 1 */}
                            <div className="mt-12 lg:mt-0">
                                <h1 className="mb-10 text-5xl font-bold tracking-tight text-[hsl(218,81%,95%)] md:text-6xl xl:text-7xl">
                                    PMD Construction Consulting LLC <br />
                                </h1>
                                {/* <p className="mb-12 text-lg text-[hsl(218,81%,95%)]">Coming this September 2023</p> */}
                            </div>
                            {/* column 2 */}
                            <div className="mb-12 lg:mb-0">
                                <p className="text-lg text-[hsl(218,81%,95%)] mb-8">
                                    PMD provides clients with a wide range of construction delivery system options.
                                    Our project management personnel are thoroughly cross-trained in all construction-related services.
                                    They can be called upon at any stage of a project to deliver valuable decision-making input.
                                </p>
                                <p className="text-lg text-[hsl(218,81%,95%)] mb-6">
                                    The current focus of company has been in the private industrial, commercial, and residential market,
                                    but intend to expand into the public sector. Projects involved with both new and existing structures, and
                                    strive to ensure a timely and cost-efficient delivery of end product.
                                </p>

                                {/* Seminars Button */}
                                <Link to="/seminars">
                                    <button
                                        type="button"
                                        className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                    >
                                        Past Seminars
                                    </button>
                                </Link>
                                {/* <button
                                    type="button"
                                    className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                >
                                    More Details
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;

import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import projectData from "../data/projectsData.json";
import theCouchTomatoPic from "../static/the-couch-tomato.jpeg";
import kopSmileCarePic from "../static/kop-smile-care.png";
import lorraineAvePic from "../static/188-lorraine-ave.png";
import kyuramenPic from "../static/kyuramen.jpeg";
import everestUrgentCarePic from "../static/everest-urgent-care.jpeg";
import nsiSurveillancePic from "../static/nsi-surveillance.jpeg";

function Projects() {
    const getImageSrc = (imageName: string) => {
        switch (imageName) {
            case "theCouchTomatoPic":
                return theCouchTomatoPic;
            case "kopSmileCarePic":
                return kopSmileCarePic;
            case "lorraineAvePic":
                return lorraineAvePic;
            case "kyuramenPic":
                return kyuramenPic;
            case "everestUrgentCarePic":
                return everestUrgentCarePic;
            case "nsiSurveillancePic":
                return nsiSurveillancePic;
            default:
                return "";
        }
    };

    return (
        <div>
            <Navbar />
            <div className="container my-24 mx-auto md:px-6">
                <div className="px-6 py-12 md:px-12 lg:text-left">
                    <div className="container mx-auto">
                        <section>
                            <div className="py-8 lg:py-10 px-4 mx-auto max-w-screen-md">
                                <div className="grid grid-cols-1 items-center gap-12">
                                    <div className="mt-10 lg:mt-0">
                                        <h2 className="mb-4 text-5xl tracking-tight font-extrabold text-center text-[hsl(218,81%,95%)] light:text-white">
                                            Highlighted Projects
                                        </h2>

                                        <p className="text-lg text-[hsl(218,81%,95%)] mb-8">
                                            With over 20 years of experience, our company has worked with hundreds of
                                            clients to build and developed projects. Explore some of the past projects
                                            we've helped lead and develop.
                                        </p>

                                        {projectData.map((project) => (
                                            <div
                                                key={project.projectName}
                                                className="mb-6 flex flex-col rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] light:bg-neutral-700 md:flex-row"
                                            >
                                                <img
                                                    className="h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                                    src={getImageSrc(project.imageSrc)}
                                                    alt={project.imageAlt}
                                                />
                                                <div className="flex flex-col justify-start p-6">
                                                    <h5 className="mb-2 text-xl font-medium text-neutral-800 light:text-neutral-50">
                                                        {project.projectName}
                                                    </h5>
                                                    <p className="mb-4 text-base text-neutral-600 light:text-neutral-200">
                                                        {project.projectDescription}
                                                    </p>
                                                    <p className="text-xs text-neutral-500 light:text-neutral-300">
                                                        Project Type: {project.projectType}
                                                    </p>
                                                    <p className="text-xs text-neutral-500 light:text-neutral-300">
                                                        Location: {project.location}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Projects;

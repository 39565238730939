import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import portrait from "../static/JudiPortrait.jpg"; // import portrait JPG file
import article from "../static/judi-chen-article-06152023.pdf";

function About() {
    return (
        <div>
            <Navbar />
            <div className="container my-24 mx-auto md:px-6">
                <div className="px-6 py-12 md:px-12 lg:text-left">
                    <div className="container mx-auto">
                        <section>
                            <div className="py-8 lg:py-10 px-4 mx-auto max-w-screen-md">
                                <div className="grid items-center gap-12">
                                    {/* column 1 */}
                                    <div className="mt-10 lg:mt-0">
                                        {/* <h2 className="mb-4 text-6xl tracking-tight font-extrabold text-left text-[hsl(218,81%,95%)] light:text-white">
                                            About
                                        </h2> */}

                                        <p className="mb-4 text-3xl font-bold tracking-tight text-[hsl(218,81%,95%)] md:text-2xl xl:text-4xl">
                                            The Company
                                        </p>
                                        <p className="text-lg text-[hsl(218,81%,95%)] mb-8">
                                            Established in April 2003, PMD Construction Consulting has proudly delivered
                                            high-quality construction plan preparation and project management services
                                            for over 500 remarkable projects. Our comprehensive solutions span the
                                            breathtaking Delaware Valley. As a minority and female-owned company, we
                                            embrace diversity and excellence. We are actively pursuing certification as
                                            a Minority Business Enterprise and a Woman Business Enterprise, opening
                                            doors to serve both the public and private sectors.
                                        </p>
                                        <p className="text-lg text-[hsl(218,81%,95%)] mb-8">
                                            At PMD, we believe in the power of seamless design and construction
                                            integration. This approach minimizes costs and delays, ensuring a smooth and
                                            efficient process for our valued clients. Our designs harmoniously blend
                                            with existing structural styles and community preferences, while
                                            incorporating unique elements that delight our clients. Discover the
                                            artistry and innovation of PMD Construction Consulting. Let us turn your
                                            vision into reality while exceeding your expectations. Together, we'll
                                            create construction projects that inspire and leave a lasting impression.
                                        </p>

                                        <div className="grid items-center gap-12 lg:grid-cols-2">
                                            {/* column 1 */}
                                            <div className="mt-12 lg:mt-0">
                                                <p className="mb-4 text-3xl  font-bold tracking-tight text-[hsl(218,81%,95%)] md:text-2xl xl:text-4xl">
                                                    The Founder
                                                </p>
                                                <p className="text-lg text-[hsl(218,81%,95%)] mb-8">
                                                    Judi Chen is the proud owner and co-founder of PMD Construction
                                                    Consulting, a company established in April 2003. With over twenty
                                                    years of experience in engineering design and construction
                                                    management, Ms. Chen brings a wealth of expertise to the
                                                    organization. As the Project Manager, she combines her solid
                                                    engineering background with excellent communication skills to ensure
                                                    the efficient execution of each project.
                                                </p>

                                                <a href={article} target="_blank" rel="noopener noreferrer">
                                                    <button
                                                        type="button"
                                                        className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                                    >
                                                        Read Article Here
                                                    </button>
                                                </a>
                                            </div>
                                            {/* column 2 */}
                                            <div className="mb-12 lg:mb-0">
                                                <img
                                                    src={portrait}
                                                    className="rounded-full w-9/12 h-9/12"
                                                    alt="Judi Chen Portrait"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;

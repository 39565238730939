import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Seminars from "./pages/Seminars";

function App() {
    return (
        <div>
            {/* The following are routes used for page navigation */}
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/seminars" element={<Seminars />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
